<template>
  <div id="deviceList">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="channelName" label="出入口名称" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="出入口类型" align="center">
        </el-table-column>
        <el-table-column
          prop="channelAddress"
          label="出入口位置"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="unlicensedCode(scope.row.channelId)"
              v-if="
                buttonList.includes(
                  scope.row.channelType === '入口' &&
                    'parkingLotList-detail-passagewayTab-unlicensedCode'
                )
              "
              >无牌码下载</el-button
            >
            <el-button
              type="text"
              @click="exitCode(scope.row.channelId)"
              v-if="
                buttonList.includes(
                  scope.row.channelType === '出口' &&
                    scope.row.parkingAisle === '否' &&
                    'parkingLotList-detail-passagewayTab-exitCode'
                )
              "
              >出口码下载</el-button
            >
            <el-button
              type="text"
              @click="goToDetail(scope.row.channelId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-passagewayTab-detail'
                )
              "
              >查看详情</el-button
            >
            <el-button
              type="text"
              @click="opening(scope.row.channelId)"
              v-if="
                buttonList.includes(
                  'parkingLotList-detail-passagewayTab-openBtn'
                )
              "
              >开闸</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode";

export default {
  data() {
    return {
      filtrate: {
        keyword: "",
        date: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async unlicensedCode(channelId) {
      try {
        this.table.loading = true;
        let res = await this.$http.get(
          `/channel/unlicensed/generate/${channelId}`
        );

        if (res.code === 0) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = `data:image/png;base64,${res.data}`;
          link.setAttribute("download", "无牌码");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error("出口码生成失败");
      } finally {
        this.table.loading = false;
      }
    },
    async exitCode(channelId) {
      try {
        const imgUrl = await QRCode.toDataURL(
          `${process.env.VUE_APP_PARKINGLOT_QR_CODE}?id=${channelId}$${this.$route.params.id}`,
          { width: 1000 }
        );
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = imgUrl;
        link.setAttribute("download", "出口码");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.$message.error("出口码生成失败");
      }
    },
    opening(channelId) {
      this.$msgbox({
        title: "提示",
        message: "此操作将完成线上开闸, 是否继续?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            try {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "处理中...";
              let res = await this.$http.post("/channel/manual/opening", {
                channelId,
              });
              if (res.code === 0) {
                if (res.data) {
                  this.$message.success("开闸成功");
                } else {
                  this.$message.error("开闸失败");
                }
              } else {
                this.$message.error(res.msg);
              }
            } catch (err) {
              this.$message.error(err);
            } finally {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }
          } else {
            done();
          }
        },
      });
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    goToDetail(channelId) {
      this.$router.push({
        name: "passagewayDetail",
        params: { id: channelId },
      });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#deviceList {
}
</style>
